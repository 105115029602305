@import url("https://fonts.googleapis.com/css2?family=Roboto");

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.RaAppBar-toolbar .MuiAvatar-root {
  color: #0c8774;
  background-color: #fff;
}
